import React from 'react';
import {
  CircularProgress,
  HStack,
  ScreenContainer,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Platform, Text, View } from 'react-native';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoCollectionApi from '../apis/XanoCollectionApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import removeGlobalScroll from '../global-functions/removeGlobalScroll';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const MandAInsightsAppScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        removeGlobalScroll(Platform.OS === 'web');
        const get_me = (await XanoCollectionApi.authMeGET(Constants))?.json;
        setGlobalVariableValue({
          key: 'ME',
          value: get_me,
        });
        if (!get_me?.email) {
          if (navigation.canGoBack()) {
            navigation.popToTop();
          }
          navigation.replace('LogInScreen', { message: get_me?.message });
        } else {
          navigation.navigate('NewslettersScreen');
        }

        setGlobalVariableValue({
          key: 'SS_SCREEN_NAME',
          value: null,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors.background.brand,
          justifyContent: 'center',
        },
        dimensions.width
      )}
    ></ScreenContainer>
  );
};

export default withTheme(MandAInsightsAppScreen);
