import React from 'react';
import {
  HStack,
  LinearGradient,
  Link,
  ScreenContainer,
  SimpleStyleFlatList,
  Table,
  TableCell,
  TableRow,
  withTheme,
} from '@draftbit/ui';
import { H5 } from '@expo/html-elements';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, Platform, Text, View } from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoCollectionApi from '../apis/XanoCollectionApi.js';
import CustomBottomNavBlock from '../components/CustomBottomNavBlock';
import CustomHeaderBlock from '../components/CustomHeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import deviceType from '../global-functions/deviceType';
import removeGlobalScroll from '../global-functions/removeGlobalScroll';
import screenNameGen from '../global-functions/screenNameGen';
import setPadding from '../global-functions/setPadding';
import palettes from '../themes/palettes';
import Breakpoints from '../utils/Breakpoints';
import * as DateUtils from '../utils/DateUtils';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';

const ReportsScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [lastPage, setLastPage] = React.useState(0);
  const [nextPage, setNextPage] = React.useState(parseInt(1, 10));
  const [reportItems, setReportItems] = React.useState([]);
  const [screenCode, setScreenCode] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'SS_SCREEN_NAME',
        value: null,
      });
      /* hidden 'Set Variable' action */
      const Reports = setGlobalVariableValue({
        key: 'pageName',
        value: 'Reports',
      });
      removeGlobalScroll(Platform.OS === 'web');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasLeftSafeArea={false}
      hasRightSafeArea={false}
      hasTopSafeArea={true}
    >
      <CustomHeaderBlock />
      {/* View 2 */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
            width: '100%',
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignSelf: { minWidth: Breakpoints.Desktop, value: 'center' },
              maxWidth: 1200,
              padding: 10,
              paddingTop: 5,
              width: '100%',
            },
            dimensions.width
          )}
        >
          <>
            {!(dimensions.width >= Breakpoints.Laptop) ? null : (
              <H5
                selectable={false}
                {...GlobalStyles.H5Styles(theme)['H5'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.H5Styles(theme)['H5'].style, {
                    fontFamily: 'Quicksand_600SemiBold',
                    fontSize: 25,
                    marginBottom: 20,
                    marginTop: [
                      { minWidth: Breakpoints.Mobile, value: 0 },
                      { minWidth: Breakpoints.Laptop, value: 20 },
                    ],
                    paddingLeft: 5,
                    textDecorationLine: 'none',
                  }),
                  dimensions.width
                )}
              >
                {'Reports'}
              </H5>
            )}
          </>
          <View
            style={StyleSheet.applyWidth(
              { gap: 10, marginBottom: 10 },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_title'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['screen_title'].style,
                  { fontFamily: 'Quicksand_400Regular' }
                ),
                dimensions.width
              )}
              suppressHighlighting={true}
            >
              {
                'Repository of the Monthly Advisor Report, Quarterly Survey Report, and other special reports'
              }
            </Text>
            {/* Text 2 */}
            <Text
              accessible={true}
              selectable={false}
              {...GlobalStyles.TextStyles(theme)['screen_title'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['screen_title'].style,
                  { fontFamily: 'Quicksand_400Regular' }
                ),
                dimensions.width
              )}
              suppressHighlighting={true}
            >
              {
                'Note: the weekly reports with opportunity-related headlines from a given week are found in the “Newsletters” tab.'
              }
            </Text>
          </View>
        </View>

        <XanoCollectionApi.FetchReportsGET
          device={deviceType(
            Platform.OS === 'web',
            Platform.OS === 'ios',
            Platform.OS === 'android'
          )}
          handlers={{
            onData: fetchData => {
              try {
                setReportItems(fetchData?.items);
                setNextPage(fetchData?.nextPage);
                setLastPage(fetchData?.pageTotal);
              } catch (err) {
                console.error(err);
              }
            },
          }}
          page={1}
          refetchOnMount={false}
          refetchOnReconnect={false}
          refetchOnWindowFocus={false}
          retry={5}
          screenCode={screenCode}
          staleTime={0}
        >
          {({ loading, error, data, refetchReports }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <View
                style={StyleSheet.applyWidth(
                  {
                    paddingLeft: {
                      minWidth: Breakpoints.Desktop,
                      value: setPadding(dimensions.width),
                    },
                    paddingRight: {
                      minWidth: Breakpoints.Desktop,
                      value: setPadding(dimensions.width),
                    },
                    width: { minWidth: Breakpoints.Desktop, value: '100%' },
                  },
                  dimensions.width
                )}
              >
                {/* Reports */}
                <LinearGradient
                  endX={100}
                  endY={100}
                  startX={0}
                  startY={0}
                  {...GlobalStyles.LinearGradientStyles(theme)[
                    'Linear Gradient'
                  ].props}
                  color1={theme.colors.text.strong}
                  color2={theme.colors.branding.primary}
                  color3={null}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.LinearGradientStyles(theme)[
                        'Linear Gradient'
                      ].style,
                      {
                        borderRadius: 0,
                        borderWidth: 0,
                        flex: null,
                        margin: [
                          { minWidth: Breakpoints.Tablet, value: 0 },
                          { minWidth: Breakpoints.Mobile, value: 0 },
                        ],
                        width: '100%',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <HStack
                    {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.HStackStyles(theme)['H Stack'].style,
                        {
                          borderBottomWidth: 1,
                          borderColor: palettes.Brand['Light Inverse'],
                          padding: [
                            { minWidth: Breakpoints.Laptop, value: 10 },
                            { minWidth: Breakpoints.Mobile, value: 10 },
                          ],
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          width: [
                            { minWidth: Breakpoints.Laptop, value: '20%' },
                            { minWidth: Breakpoints.Mobile, value: '30%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'screen_title_stockH'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['screen_title_stockH']
                            .style,
                          dimensions.width
                        )}
                      >
                        {' Published'}
                      </Text>
                    </View>
                    {/* View 2 */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          width: [
                            { minWidth: Breakpoints.Laptop, value: '80%' },
                            { minWidth: Breakpoints.Mobile, value: '70%' },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* Text 2 */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)[
                          'screen_title_stockH'
                        ].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['screen_title_stockH']
                            .style,
                          dimensions.width
                        )}
                      >
                        {'Title (PDF download)'}
                      </Text>
                    </View>
                  </HStack>
                  <SimpleStyleFlatList
                    data={reportItems}
                    decelerationRate={'normal'}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(listData, index) => listData?.id}
                    keyboardShouldPersistTaps={'never'}
                    listKey={'a4WsXcZ0'}
                    nestedScrollEnabled={false}
                    onEndReachedThreshold={0.5}
                    pagingEnabled={false}
                    renderItem={({ item, index }) => {
                      const listData = item;
                      return (
                        <>
                          {/* H Stack 2 */}
                          <HStack
                            {...GlobalStyles.HStackStyles(theme)['H Stack']
                              .props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.HStackStyles(theme)['H Stack']
                                  .style,
                                {
                                  borderBottomWidth: 1,
                                  borderColor: palettes.Brand['Light Inverse'],
                                  justifyContent: {
                                    minWidth: Breakpoints.Desktop,
                                    value: 'center',
                                  },
                                  maxWidth: {
                                    minWidth: Breakpoints.Desktop,
                                    value: '100%',
                                  },
                                  padding: [
                                    { minWidth: Breakpoints.Laptop, value: 10 },
                                    { minWidth: Breakpoints.Mobile, value: 10 },
                                  ],
                                  width: [
                                    {
                                      minWidth: Breakpoints.BigScreen,
                                      value: '100%',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '100%',
                                    },
                                  ],
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  width: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '20%',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '30%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              <Text
                                accessible={true}
                                selectable={false}
                                {...GlobalStyles.TextStyles(theme)[
                                  'screen_title_stockH'
                                ].props}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.TextStyles(theme)[
                                    'screen_title_stockH'
                                  ].style,
                                  dimensions.width
                                )}
                              >
                                {' '}
                                {DateUtils.format(
                                  listData?.published,
                                  'DD/MM/YYYY'
                                )}
                              </Text>
                            </View>
                            {/* View 2 */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  width: [
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '80%',
                                    },
                                    {
                                      minWidth: Breakpoints.Mobile,
                                      value: '70%',
                                    },
                                  ],
                                },
                                dimensions.width
                              )}
                            >
                              {/* Link 2 */}
                              <Link
                                accessible={true}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      await WebBrowser.openBrowserAsync(
                                        `${listData?.file?.url}`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                selectable={false}
                                {...GlobalStyles.LinkStyles(theme)['Link']
                                  .props}
                                adjustsFontSizeToFit={true}
                                ellipsizeMode={'clip'}
                                numberOfLines={1}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.LinkStyles(theme)['Link']
                                      .style,
                                    {
                                      color: palettes.App.Orange,
                                      textDecorationLine: 'underline',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={`${listData?.title?.name}`}
                              />
                            </View>
                          </HStack>
                        </>
                      );
                    }}
                    snapToAlignment={'start'}
                    numColumns={1}
                    showsHorizontalScrollIndicator={false}
                    showsVerticalScrollIndicator={false}
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: {
                          minWidth: Breakpoints.Desktop,
                          value: 'stretch',
                        },
                        alignItems: [
                          { minWidth: Breakpoints.BigScreen, value: 'stretch' },
                          { minWidth: Breakpoints.Desktop, value: 'stretch' },
                        ],
                        alignSelf: {
                          minWidth: Breakpoints.Desktop,
                          value: 'stretch',
                        },
                        marginBottom:
                          dimensions.width >= Breakpoints.Laptop
                            ? 0
                            : Platform.OS === 'ios'
                            ? 65
                            : 35,
                        maxHeight: dimensions.height - 290,
                        width: '100%',
                        zIndex: 1,
                      },
                      dimensions.width
                    )}
                  />
                </LinearGradient>
              </View>
            );
          }}
        </XanoCollectionApi.FetchReportsGET>
      </View>
      <CustomBottomNavBlock />
    </ScreenContainer>
  );
};

export default withTheme(ReportsScreen);
