const holidayText = (nlType, date) => {
  // Type the code for the body of your function or hook here.
  // Functions can be triggered via Button/Touchable actions.
  // Hooks are run per ReactJS rules.

  /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

  console.log(nlType, date, new Date(date));
  if (nlType === 'Nordic') {
    if (
      new Date(date) >= new Date('2024-12-20') &&
      new Date(date) <= new Date('2024-12-30')
    ) {
      return 'Newsletter during the holidays: for the period 24 December to 02 January no newsletter, or only a minimalistic newsletter, will be sent out. We are back full speed, including Pitch of the Day and proprietary coverage, on January 03, 2025. In the meantime we refer to our web/mobile app where transactional events will be updated continuously. We wish everyone a joyful holiday season and happy New Year.';
    } else if (new Date(date) == new Date('2025-01-03')) {
      return 'Year-end deal reporting for advisors: the deadline is Tuesday 07 January EOD, send to support@mainsights.io or ma@nordicknowledgepartners.com';
    }
  } else if (nlType === 'DACH') {
    if (
      new Date(date) >= new Date('2024-12-20') &&
      new Date(date) <= new Date('2024-12-30')
    ) {
      return 'Newsletter during the holidays: No newsletter will be sent out during the period 24 December to 03 January, 2025. We are back full speed, including Pitch of the Day and proprietary coverage, on January 06, 2025. In the meantime we refer to our web/mobile app where transactional events will be updated continuously. We wish everyone a joyful holiday season and happy New Year.';
    } else if (
      new Date(date) >= new Date('2025-01-06') &&
      new Date(date) <= new Date('2025-01-07')
    ) {
      return 'Year-end deal reporting for advisors: the deadline is Thursday 09 January EOD, send to support@mainsights.io or ma@nordicknowledgepartners.com';
    }
  }
};

export default holidayText;
