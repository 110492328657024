import * as React from 'react';
import { Provider as ThemeProvider } from '@draftbit/ui';
import { useFonts } from 'expo-font';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import {
  ActivityIndicator,
  AppState,
  Platform,
  StatusBar,
  Text,
  TextInput,
  View,
} from 'react-native';
import {
  SafeAreaFrameContext,
  SafeAreaProvider,
  initialWindowMetrics,
} from 'react-native-safe-area-context';
import { QueryClient, QueryClientProvider } from 'react-query';
import AppNavigator from './AppNavigator';
import Fonts from './config/Fonts.js';
import { GlobalVariableProvider } from './config/GlobalVariableContext';
import cacheAssetsAsync from './config/cacheAssetsAsync';
import Draftbit from './themes/Draftbit';
import useWindowDimensions from './utils/useWindowDimensions';

SplashScreen.preventAutoHideAsync();

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: true,
      staleTime: 0,
      cacheTime: 0
    },
  },
});

const App = () => {
  const [areAssetsCached, setAreAssetsCached] = React.useState(false);

  const [fontsLoaded] = useFonts({
    Poppins_400Regular: Fonts.Poppins_400Regular,
    Poppins_900Black: Fonts.Poppins_900Black,
    Quicksand_300Light: Fonts.Quicksand_300Light,
    Quicksand_400Regular: Fonts.Quicksand_400Regular,
    Quicksand_500Medium: Fonts.Quicksand_500Medium,
    Quicksand_700Bold: Fonts.Quicksand_700Bold,
    Quicksand_600SemiBold: Fonts.Quicksand_600SemiBold,
  });

  React.useEffect(() => {
    async function prepare() {
      try {
        await cacheAssetsAsync();
      } catch (e) {
        console.warn(e);
      } finally {
        setAreAssetsCached(true);
      }
    }

    prepare();
  }, []);

  const dimensions = useWindowDimensions();

  // SafeAreaProvider sets the 'frame' once and does not update when the window size changes (on web).
  // This is particularly problematic for drawer navigators that depend on the frame size to render the drawer.
  // This overrides the value of the frame to match the current window size which addresses the issue.
  //
  // The Drawer snippet that relies on useSafeAreaFrame: https://github.com/react-navigation/react-navigation/blob/bddcc44ab0e0ad5630f7ee0feb69496412a00217/packages/drawer/src/views/DrawerView.tsx#L112
  // Issue regarding broken useSafeAreaFrame: https://github.com/th3rdwave/react-native-safe-area-context/issues/184
  const SafeAreaFrameContextProvider =
    Platform.OS === 'web' ? SafeAreaFrameContext.Provider : React.Fragment;

  const isReady = areAssetsCached && fontsLoaded;
  const onLayoutRootView = React.useCallback(async () => {
    if (isReady) {
      await SplashScreen.hideAsync();
    }
  }, [isReady]);

  if (!isReady) {
    return null;
  }

  return (
    <>
      {Platform.OS === 'android' ? (
        <StatusBar barStyle={'light-content'} />
      ) : null}
      <SafeAreaProvider
        initialMetrics={initialWindowMetrics}
        onLayout={onLayoutRootView}
      >
        <SafeAreaFrameContextProvider
          value={{
            x: 0,
            y: 0,
            width: dimensions.width,
            height: dimensions.height,
          }}
        >
          <GlobalVariableProvider>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider
                themes={[Draftbit]}
                breakpoints={{}}
                initialThemeName={'Draftbit'}
              >
                <AppNavigator />
              </ThemeProvider>
            </QueryClientProvider>
          </GlobalVariableProvider>
        </SafeAreaFrameContextProvider>
      </SafeAreaProvider>
    </>
  );
};

export default App;
